export const BackupCategories = [
    {
        "_id": "65f40ceffa0e39436c738024",
        "name": "CLIENTQUOTE",
        "description": "default category description",
        "image": "/images/tablets-category.png",
        "attrs": [],
        "__v": 0,
        "display": true
    },
    {
        "_id": "64d338d8bba12d35590757e3",
        "name": "ELECTRICAL/BORE-PUMPS",
        "description": "ELECTRICAL/BORE-PUMPS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590757e4",
        "name": "ELECTRICAL/CABLES",
        "description": "ELECTRICAL/CABLES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64e6d43267640f173aefea14",
        "name": "ELECTRICAL/CABLES/CABLE-MANAGEMENT",
        "description": "ELECTRICAL/CABLES/CABLE-MANAGEMENT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64e6d47167640f173aefea15",
        "name": "ELECTRICAL/CABLES/CABLE-MANAGEMENT/CABLE-LADDER",
        "description": "ELECTRICAL/CABLES/CABLE-MANAGEMENT/CABLE-LADDER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64fad5665493356e090358f4",
        "name": "ELECTRICAL/CABLES/CABLE-MANAGEMENT/DUCT",
        "description": "ELECTRICAL/CABLES/CABLE-MANAGEMENT/DUCT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590757e5",
        "name": "ELECTRICAL/CABLES/CONSTRUCTION-CABLES",
        "description": "ELECTRICAL/CABLES/CONSTRUCTION-CABLES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590757f6",
        "name": "ELECTRICAL/ENCLOSURES",
        "description": "ELECTRICAL/ENCLOSURES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a4824c19035d0b635124d4",
        "name": "ELECTRICAL/INSTRUMENTATION",
        "description": "ELECTRICAL/INSTRUMENTATION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a4840e19035d0b635124d7",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a4a77a19035d0b63512500",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/CHEMICAL-LIQUID-VALVES",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/CHEMICAL-LIQUID-VALVES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "65b9c685c0cda9c9e584c1be",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/CHEMICALS",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/CHEMICALS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "65a48ee019035d0b635124e6",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/FILTERS",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/FILTERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a4845f19035d0b635124d8",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/FITTING_TUBING",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/FITTING_TUBING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a4afe119035d0b63512503",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/PNEUMATIC-EQUIPMENT",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/PNEUMATIC-EQUIPMENTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b9c7cfc0cda9c9e584c1c1",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/PNEUMATIC-TOOLS_ACCESSORIES",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/PNEUMATIC-TOOLS_ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a4b82e19035d0b63512512",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/PROCESS-VALVES",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/PROCESS-VALVES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "65b9c6ddc0cda9c9e584c1bf",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/PUMPS-VALVES-PARTS",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/PUMPS-VALVES-PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b9cd3bc0cda9c9e584c1c4",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/PUMPS-VALVES-PARTS/CHEMICAL",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/PUMPS-VALVES-PARTS/CHEMICAL",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b9c70dc0cda9c9e584c1c0",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/PUMPS-VALVES-PARTS/FITTING-PARTS",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/PUMPS-VALVES-PARTS/FITTING-PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65a49ac519035d0b635124f0",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/SILENCERS",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/SILENCERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "65a4a32f19035d0b635124fc",
        "name": "ELECTRICAL/INSTRUMENTATION/SMC/SWITCHES",
        "description": "ELECTRICAL/INSTRUMENTATION/SMC/SWITCHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907580c",
        "name": "ELECTRICAL/LIGHTING",
        "description": "ELECTRICAL/LIGHTING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075815",
        "name": "ELECTRICAL/MOTORS",
        "description": "ELECTRICAL/MOTORS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6503fcab5493356e0903596d",
        "name": "ELECTRICAL/PANEL-BOARD",
        "description": "ELECTRICAL/PANEL-BOARD",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907581a",
        "name": "ELECTRICAL/SWITCH-GEAR",
        "description": "ELECTRICAL/SWITCH-GEAR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "650bf4ffd7e3f3f7276986b2",
        "name": "ELECTRICAL/SWITCH-GEAR/APPLIANCE-INLETS",
        "description": "ELECTRICAL/SWITCH-GEAR/APPLIANCE-INLETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907581b",
        "name": "ELECTRICAL/SWITCH-GEAR/CHEMICAL-RESISTANT-SWITCHGEAR",
        "description": "ELECTRICAL/SWITCH-GEAR/CHEMICAL-RESISTANT-SWITCHGEAR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907581c",
        "name": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION",
        "description": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f63",
        "name": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/ASSORTED",
        "description": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/ASSORTED",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907581d",
        "name": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/CIRCUIT-BREAKERS",
        "description": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/CIRCUIT-BREAKERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907581e",
        "name": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/CONTACTORS",
        "description": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/CONTACTORS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075820",
        "name": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/OVERLOADS",
        "description": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/OVERLOADS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075821",
        "name": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/RELAYS",
        "description": "ELECTRICAL/SWITCH-GEAR/CIRCUIT-PROTECTION/RELAYS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075822",
        "name": "ELECTRICAL/SWITCH-GEAR/EXTENSION-SOCKETS",
        "description": "ELECTRICAL/SWITCH-GEAR/EXTENSION-SOCKETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "650903a55493356e0903597f",
        "name": "ELECTRICAL/SWITCH-GEAR/IP66-ENCLOSURE",
        "description": "ELECTRICAL/SWITCH-GEAR/IP66-ENCLOSURE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075823",
        "name": "ELECTRICAL/SWITCH-GEAR/IP66-PLUGS",
        "description": "ELECTRICAL/SWITCH-GEAR/IP66-PLUGS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075824",
        "name": "ELECTRICAL/SWITCH-GEAR/IP66-SOCKET-OUTLETS",
        "description": "ELECTRICAL/SWITCH-GEAR/IP66-SOCKET-OUTLETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075825",
        "name": "ELECTRICAL/SWITCH-GEAR/IP66-SWITCHED-SOCKETS",
        "description": "ELECTRICAL/SWITCH-GEAR/IP66-SWITCHED-SOCKETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075826",
        "name": "ELECTRICAL/SWITCH-GEAR/IP66-SWITCHES",
        "description": "ELECTRICAL/SWITCH-GEAR/IP66-SWITCHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075827",
        "name": "ELECTRICAL/SWITCH-GEAR/OUTLETS-SWITCHES",
        "description": "ELECTRICAL/SWITCH-GEAR/OUTLETS-SWITCHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "650904685493356e09035980",
        "name": "ELECTRICAL/SWITCH-GEAR/RCD-PROTECTED-OUTLETS",
        "description": "ELECTRICAL/SWITCH-GEAR/RCD-PROTECTED-OUTLETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075828",
        "name": "ELECTRICAL/WIRING-ACCESSORIES",
        "description": "ELECTRICAL/WIRING-ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075866",
        "name": "HAND-TOOLS/CRIMPERS",
        "description": "HAND-TOOLS/CRIMPERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075861",
        "name": "HAND-TOOLS/CUTTING-TOOLS",
        "description": "HAND-TOOLS/CUTTING-TOOLS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655424c57752a1732e1dffa1",
        "name": "HAND-TOOLS/ELECTRICAL/CABLE-CUTTERS",
        "description": "HAND-TOOLS/ELECTRICAL/CABLE-CUTTERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907586d",
        "name": "HAND-TOOLS/GAS-TOOLS",
        "description": "HAND-TOOLS/GAS-TOOLS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f6d",
        "name": "HAND-TOOLS/HAMMERS",
        "description": "HAND-TOOLS/HAMMERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655c77dc7752a1732e1dffe9",
        "name": "HAND-TOOLS/HEX-KEY-SETS",
        "description": "HAND-TOOLS/HEX-KEY-SETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075876",
        "name": "HAND-TOOLS/KNIVES",
        "description": "HAND-TOOLS/KNIVES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907587c",
        "name": "HAND-TOOLS/MEASURING",
        "description": "HAND-TOOLS/MEASURING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075880",
        "name": "HAND-TOOLS/PLIERS",
        "description": "HAND-TOOLS/PLIERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655d536d7752a1732e1dffee",
        "name": "HAND-TOOLS/PUNCH_CHISEL-SET",
        "description": "HAND-TOOLS/PUNCH_CHISEL-SET",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075869",
        "name": "HAND-TOOLS/RATCHET-PODGERS",
        "description": "HAND-TOOLS/RATCHET-PODGERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075867",
        "name": "HAND-TOOLS/SCREW-DRIVERS",
        "description": "HAND-TOOLS/SCREW-DRIVERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6552ed447752a1732e1dff82",
        "name": "HAND-TOOLS/SEALANT-ACCESSORIES",
        "description": "HAND-TOOLS/SEALANT-ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907586a",
        "name": "HAND-TOOLS/SLOGGING-SPANNERS",
        "description": "HAND-TOOLS/SLOGGING-SPANNERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "655c29e57752a1732e1dffc7",
        "name": "HAND-TOOLS/SPANNERS_SOCKETS",
        "description": "HAND-TOOLS/SPANNERS_SOCKETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655c5e8d7752a1732e1dffe0",
        "name": "HAND-TOOLS/SPANNERS_SOCKETS/RATCHET-PODGERS",
        "description": "HAND-TOOLS/SPANNERS_SOCKETS/RATCHET-PODGERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655c5ed47752a1732e1dffe1",
        "name": "HAND-TOOLS/SPANNERS_SOCKETS/SLOGGING-SPANNERS",
        "description": "HAND-TOOLS/SPANNERS_SOCKETS/SLOGGING-SPANNERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655c2c287752a1732e1dffc9",
        "name": "HAND-TOOLS/SPANNERS_SOCKETS/SOCKETS",
        "description": "HAND-TOOLS/SPANNERS_SOCKETS/SOCKETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655c2a517752a1732e1dffc8",
        "name": "HAND-TOOLS/SPANNERS_SOCKETS/SPANNERS",
        "description": "HAND-TOOLS/SPANNERS_SOCKETS/SPANNERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655d52637752a1732e1dffed",
        "name": "HAND-TOOLS/TOOL-BAGS",
        "description": "HAND-TOOLS/TOOL-BAGS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "655c41c77752a1732e1dffce",
        "name": "HAND-TOOLS/TOOL-CHESTS",
        "description": "HAND-TOOLS/TOOLS-CHEST",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907586b",
        "name": "HAND-TOOLS/WRENCHES",
        "description": "HAND-TOOLS/WRENCHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075887",
        "name": "INDUSTRIAL/ABRASIVES-CUTTING",
        "description": "INDUSTRIAL/ABRASIVES-CUTTING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "659f975a19035d0b635124ca",
        "name": "INDUSTRIAL/AUTOMOTIVE",
        "description": "INDUSTRIAL/AUTOMOTIVE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075893",
        "name": "INDUSTRIAL/DRILLING",
        "description": "INDUSTRIAL/DRILLING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075894",
        "name": "INDUSTRIAL/DRILLING/ARBORS",
        "description": "INDUSTRIAL/DRILLING/ARBORS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075895",
        "name": "INDUSTRIAL/DRILLING/DRILL-BITS",
        "description": "INDUSTRIAL/DRILLING/DRILL-BITS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075898",
        "name": "INDUSTRIAL/DRILLING/DRILL-SETS",
        "description": "INDUSTRIAL/DRILLING/DRILL-SETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075899",
        "name": "INDUSTRIAL/DRILLING/HSS-BROACH-CUTTERS",
        "description": "INDUSTRIAL/DRILLING/HSS-BROACH-CUTTERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f71",
        "name": "INDUSTRIAL/DRILLING/HSS-HOLESAWS",
        "description": "INDUSTRIAL/DRILLING/HSS-HOLESAWS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64fef5aa5493356e09035922",
        "name": "INDUSTRIAL/DRILLING/TAPER",
        "description": "INDUSTRIAL/DRILLING/TAPER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758a3",
        "name": "INDUSTRIAL/DRILLING/TCT-HOLE-CUTTER",
        "description": "INDUSTRIAL/DRILLING/TCT-HOLE-CUTTER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758a4",
        "name": "INDUSTRIAL/DRILLING/TCT-HOLESAWS",
        "description": "INDUSTRIAL/DRILLING/TCT-HOLESAWS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f37a5cc8041473041f81",
        "name": "INDUSTRIAL/FASTENERS",
        "description": "INDUSTRIAL/FASTENERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758a5",
        "name": "INDUSTRIAL/FASTENERS/BOLTS",
        "description": "INDUSTRIAL/FASTENERS/BOLTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758ae",
        "name": "INDUSTRIAL/FASTENERS/HARDWARE",
        "description": "INDUSTRIAL/FASTENERS/HARDWARE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758b2",
        "name": "INDUSTRIAL/FASTENERS/NUTS",
        "description": "INDUSTRIAL/FASTENERS/NUTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758b8",
        "name": "INDUSTRIAL/FASTENERS/ROD-THREADED",
        "description": "INDUSTRIAL/FASTENERS/ROD-THREADED",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758ba",
        "name": "INDUSTRIAL/FASTENERS/SCREWS",
        "description": "INDUSTRIAL/FASTENERS/SCREWS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758bf",
        "name": "INDUSTRIAL/FASTENERS/STUD-CHEMSET",
        "description": "INDUSTRIAL/FASTENERS/STUD-CHEMSET",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758c1",
        "name": "INDUSTRIAL/FASTENERS/WASHERS",
        "description": "INDUSTRIAL/FASTENERS/WASHERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6566cf414ee13859f1eb1207",
        "name": "INDUSTRIAL/HANDHELD-RADIOS",
        "description": "INDUSTRIAL/HANDHELD-RADIOS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758c8",
        "name": "INDUSTRIAL/HOSES_FITTINGS",
        "description": "INDUSTRIAL/HOSE_FITTINGS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d62314ee13859f1eb123f",
        "name": "INDUSTRIAL/HOSES_FITTINGS/ACCESSORIES",
        "description": "INDUSTRIAL/HOSE_FITTINGS/ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d61194ee13859f1eb1239",
        "name": "INDUSTRIAL/HOSES_FITTINGS/AIR",
        "description": "INDUSTRIAL/HOSE_FITTINGS/AIR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d60e74ee13859f1eb1238",
        "name": "INDUSTRIAL/HOSES_FITTINGS/AIR_WATER",
        "description": "INDUSTRIAL/HOSE_FITTINGS/AIR_WATER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d61724ee13859f1eb123d",
        "name": "INDUSTRIAL/HOSES_FITTINGS/BARRIER",
        "description": "INDUSTRIAL/HOSE_FITTINGS/BARRIER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d61484ee13859f1eb123b",
        "name": "INDUSTRIAL/HOSES_FITTINGS/CABLE",
        "description": "INDUSTRIAL/HOSE_FITTINGS/CABLE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d61a14ee13859f1eb123e",
        "name": "INDUSTRIAL/HOSES_FITTINGS/OIL_GREASE",
        "description": "INDUSTRIAL/HOSE_FITTINGS/OIL_GREASE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d61304ee13859f1eb123a",
        "name": "INDUSTRIAL/HOSES_FITTINGS/WATER",
        "description": "INDUSTRIAL/HOSE_FITTINGS/WATER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f37a5cc8041473041f82",
        "name": "INDUSTRIAL/LADDERS",
        "description": "INDUSTRIAL/LADDERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758d7",
        "name": "INDUSTRIAL/LUBRICANTS",
        "description": "INDUSTRIAL/LUBRICANTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758de",
        "name": "INDUSTRIAL/PAINTING-ACCESSORIES",
        "description": "INDUSTRIAL/PAINTING-ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758dd",
        "name": "INDUSTRIAL/PAINTS",
        "description": "INDUSTRIAL/PAINTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758e2",
        "name": "INDUSTRIAL/PIPE-REPAIR",
        "description": "INDUSTRIAL/PIPE-REPAIR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "651b925816c14b6892aac778",
        "name": "INDUSTRIAL/PRINTERS",
        "description": "INDUSTRIAL/PRINTERS",
        "image": "/images/tablets-category.png",
        "attrs": [],
        "__v": 0,
        "display": true
    },
    {
        "_id": "64d338d8bba12d35590758e3",
        "name": "INDUSTRIAL/PUMPS-VALVES-PARTS",
        "description": "INDUSTRIAL/PUMPS-VALVES-PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758e4",
        "name": "INDUSTRIAL/PUMPS-VALVES-PARTS/FITTINGS-PARTS",
        "description": "INDUSTRIAL/PUMPS-VALVES-PARTS/FITTINGS-PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f37a5cc8041473041f85",
        "name": "INDUSTRIAL/PUMPS-VALVES-PARTS/PUMPS",
        "description": "INDUSTRIAL/PUMPS-VALVES-PARTS/PUMPS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64fe85d65493356e09035904",
        "name": "INDUSTRIAL/PUMPS-VALVES-PARTS/RUBBER",
        "description": "INDUSTRIAL/PUMPS-VALVES-PARTS/RUBBER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758ec",
        "name": "INDUSTRIAL/PUMPS-VALVES-PARTS/TRANSFER-KITS",
        "description": "INDUSTRIAL/PUMPS-VALVES-PARTS/TRANSFER-KITS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6552ed447752a1732e1dff81",
        "name": "INDUSTRIAL/SEALANT",
        "description": "INDUSTRIAL/SEALANT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758e1",
        "name": "INDUSTRIAL/STRAPS_TAPES",
        "description": "INDUSTRIAL/STRAPS-TAPES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65669d0f4ee13859f1eb11f8",
        "name": "INDUSTRIAL/WIRE-BRUSHES",
        "description": "INDUSTRIAL/WIRE-BRUSHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "655d51327752a1732e1dffec",
        "name": "INDUSTRIAL/WORKSHOP-CABINETS",
        "description": "INDUSTRIAL/WORKSHOP-CABINETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "651a6316592242bdb61c0121",
        "name": "INDUSTRIAL/WORKSHOP-MACHINERY",
        "description": "INDUSTRIAL/WORKSHOP-MACHINERY",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af7668c0cda9c9e584c17f",
        "name": "MECHANICAL/FABRICATION",
        "description": "MECHANICAL/FABRICATION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f68",
        "name": "MECHANICAL/FABRICATION/MIG-WELDING",
        "description": "MECHANICAL/FABRICATION/MIG-WELDING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d5ff14ee13859f1eb1234",
        "name": "MECHANICAL/FABRICATION/MIG-WELDING/ACCESSORIES",
        "description": "MECHANICAL/FABRICATION/MIG-WELDING/ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d5fd24ee13859f1eb1233",
        "name": "MECHANICAL/FABRICATION/MIG-WELDING/GAS-EQUIPMENT",
        "description": "MECHANICAL/FABRICATION/MIG-WELDING/GAS-EQUIPMENTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d60244ee13859f1eb1236",
        "name": "MECHANICAL/FABRICATION/MIG-WELDING/MIG-WIRE",
        "description": "MECHANICAL/FABRICATION/MIG-WELDING/MIG-WIRE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656d60554ee13859f1eb1237",
        "name": "MECHANICAL/FABRICATION/MIG-WELDING/WELDER",
        "description": "MECHANICAL/FABRICATION/MIG-WELDING/WELDER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "656d5f894ee13859f1eb1232",
        "name": "MECHANICAL/FABRICATION/MIG-WELDING/WELDING-EQUIPMENT",
        "description": "MECHANICAL/FABRICATION/MIG-WELDING/WELDING-EQUIPMENTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f69",
        "name": "MECHANICAL/FABRICATION/OXYGEN-ACETYLENE-CUTTING-HEATING",
        "description": "MECHANICAL/FABRICATION/OXYGEN-ACETYLENE-CUTTING-HEATING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f6a",
        "name": "MECHANICAL/FABRICATION/TIG-STICK-WELDING",
        "description": "MECHANICAL/FABRICATION/TIG-STICK-WELDING",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656e902b4ee13859f1eb126a",
        "name": "MECHANICAL/FABRICATION/WELDERS",
        "description": "MECHANICAL/FABRICATION/WELDERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65684ed54ee13859f1eb121b",
        "name": "MECHANICAL/FABRICATION/WELDING-ACCESSORIES_TOOLS",
        "description": "MECHANICAL/FABRICATION/WELDING-ACCESSORIES_TOOLS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f6b",
        "name": "MECHANICAL/FABRICATION/WELDING-HELMETS_PARTS",
        "description": "MECHANICAL/FABRICATION/WELDING-HELMETS_PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f3265cc8041473041f6c",
        "name": "MECHANICAL/FABRICATION/WELDING-PPE",
        "description": "MECHANICAL/FABRICATION/WELDING-PPE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af768cc0cda9c9e584c180",
        "name": "MECHANICAL/HYDRAULICS",
        "description": "MECHANICAL/HYDRAULICS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af721efa633638330e4da2",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46d6",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/45-DEGREE-ELBOWS",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/45-DEGREE-ELBOWS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46d7",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/45-DEGREE-ELBOWS/ELBOW-BLOCK",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/45-DEGREE-ELBOWS/ELBOW-BLOCK",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46d8",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/45-DEGREE-ELBOWS/ELBOW-TUBE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/45-DEGREE-ELBOWS/ELBOW-TUBE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46d9",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/67-DEGREE-ELBOWS",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/67-DEGREE-ELBOWS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46da",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/67-DEGREE-ELBOWS/ELBOW-BLOCK",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/67-DEGREE-ELBOWS/ELBOW-BLOCK",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46db",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/90-DEGREE-ELBOWS",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/90-DEGREE-ELBOWS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46dc",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/90-DEGREE-ELBOWS/ELBOW-BLOCK",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/90-DEGREE-ELBOWS/ELBOW-BLOCK",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46dd",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/90-DEGREE-ELBOWS/ELBOW-TUBE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/90-DEGREE-ELBOWS/ELBOW-TUBE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46de",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/90-DEGREE-ELBOWS/ELBOW-TUBE-LONG",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/90-DEGREE-ELBOWS/ELBOW-TUBE-LONG",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46df",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/CAP",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/CAP",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e0",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/PLUG",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/PLUG",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e1",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/STRAIGHT",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/STRAIGHT",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e2",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/STRAIGHT/BULK-HEAD",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/STRAIGHT/BULK-HEAD",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e3",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/STRAIGHT/FLANGE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/STRAIGHT/FLANGE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e4",
        "name": "MECHANICAL/HYDRAULICS/ADAPTORS/STRAIGHT/NIPPLE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/ADAPTORS/STRAIGHT/NIPPLE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e4703",
        "name": "MECHANICAL/HYDRAULICS/AQUAWASH",
        "description": "INDUSTRIAL/HOSES_FITTINGS/AQUAWASH",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65828e0e8a40259f7c21d52d",
        "name": "MECHANICAL/HYDRAULICS/GASKETS",
        "description": "MECHANICAL/HYDRAULICS/GASKETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e5",
        "name": "MECHANICAL/HYDRAULICS/HOSE-PROTECTOR",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-PROTECTOR",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e6",
        "name": "MECHANICAL/HYDRAULICS/HOSE-PROTECTOR/HOSE-ASSEMBLY-ACCESSORIES",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-PROTECTOR/HOSE-ASSEMBLY-ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e7",
        "name": "MECHANICAL/HYDRAULICS/HOSE-PROTECTOR/HOSE-PROTECTION",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-PROTECTOR/HOSE-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e8",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46ed",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46ee",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/110-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/110-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46ef",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/22.5-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/22.5-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f0",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/30-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/30-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f1",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/45-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/45-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f2",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/60-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/60-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f3",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/67.5-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/67.5-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f4",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/90-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/90-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f5",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R12/STRAIGHT",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R12/STRAIGHT",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f6",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f7",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/105-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/105-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f8",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/110-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/110-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46f9",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/15-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/15-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46fa",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/22.5-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/22.5-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46fb",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/30-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/30-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46fc",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/45-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/45-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46fd",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/60-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/60-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46fe",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/67.5-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/67.5-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46ff",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/70-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/70-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e4700",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/90-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/90-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e4701",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/ROCK-BREAKER-TAILS",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/ROCK-BREAKER-TAILS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e4702",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R13_100R15/STRAIGHT",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R13_100R15/STRAIGHT",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46e9",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R1_100R2",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R1_100R2",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46ea",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R1_100R2/45-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R1_100R2/45-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46eb",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R1_100R2/90-DEGREE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R1_100R2/90-DEGREE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e46ec",
        "name": "MECHANICAL/HYDRAULICS/HOSE-TAILS/100R1_100R2/STRAIGHT",
        "description": "INDUSTRIAL/HOSES_FITTINGS/HOSE-TAILS/100R1_100R2/STRAIGHT",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e4705",
        "name": "MECHANICAL/HYDRAULICS/TAIPAN-HOSE",
        "description": "INDUSTRIAL/HOSES_FITTINGS/TAIPAN-HOSE",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65af4a3cfa633638330e470a",
        "name": "MECHANICAL/HYDRAULICS/TEST-EQUIPMENT",
        "description": "INDUSTRIAL/HOSES_FITTINGS/TEST-EQUIPMENT",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758ed",
        "name": "MECHANICAL/HYDRAULICS/VALVES",
        "description": "MECHANICAL/HYDRAULICS/VALVES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "656e875b4ee13859f1eb1261",
        "name": "MECHANICAL/WEAR-PLATES",
        "description": "MECHANICAL/WEAR-PLATES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65d824fcf230487b679fad31",
        "name": "POWER-AIR/AIR-CONDITIONS",
        "description": "POWER-AIR/AIR-CONDITIONS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f2",
        "name": "POWER-AIR/AIR-TOOLS",
        "description": "POWER-AIR/AIR-TOOLS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f3",
        "name": "POWER-AIR/AIR-TOOLS/AIR-BREAKER",
        "description": "POWER-AIR/AIR-TOOLS/AIR-BREAKER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f4",
        "name": "POWER-AIR/AIR-TOOLS/ANGLE-DIE-GRINDERS",
        "description": "POWER-AIR/AIR-TOOLS/ANGLE-DIE-GRINDERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f5",
        "name": "POWER-AIR/AIR-TOOLS/CHIPPING-HAMMER",
        "description": "POWER-AIR/AIR-TOOLS/CHIPPING-HAMMER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f6",
        "name": "POWER-AIR/AIR-TOOLS/DIE-GRINDERS",
        "description": "POWER-AIR/AIR-TOOLS/DIE-GRINDERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f7",
        "name": "POWER-AIR/AIR-TOOLS/DRILL",
        "description": "POWER-AIR/AIR-TOOLS/DRILL",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f8",
        "name": "POWER-AIR/AIR-TOOLS/HYDRAULIC-JACK",
        "description": "POWER-AIR/AIR-TOOLS/HYDRAULIC-JACK",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758f9",
        "name": "POWER-AIR/AIR-TOOLS/HYDRAULIC-RIVERTER",
        "description": "POWER-AIR/AIR-TOOLS/HYDRAULIC-RIVERTER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758fa",
        "name": "POWER-AIR/AIR-TOOLS/IMPACT-WRENCHES",
        "description": "POWER-AIR/AIR-TOOLS/IMPACT-WRENCHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758fb",
        "name": "POWER-AIR/AIR-TOOLS/NEEDLE-SCALAR",
        "description": "POWER-AIR/AIR-TOOLS/NEEDLE-SCALAR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758fc",
        "name": "POWER-AIR/AIR-TOOLS/OIL",
        "description": "POWER-AIR/AIR-TOOLS/OIL",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758fd",
        "name": "POWER-AIR/AIR-TOOLS/POLISHER",
        "description": "POWER-AIR/AIR-TOOLS/POLISHER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758fe",
        "name": "POWER-AIR/AIR-TOOLS/RATCHET-WRENCHES",
        "description": "POWER-AIR/AIR-TOOLS/RATCHET-WRENCHES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d35590758ff",
        "name": "POWER-AIR/AIR-TOOLS/SANDER",
        "description": "POWER-AIR/AIR-TOOLS/SANDER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075900",
        "name": "POWER-AIR/AIR-TOOLS/SAW",
        "description": "POWER-AIR/AIR-TOOLS/SAW",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075901",
        "name": "POWER-AIR/AIR-TOOLS/TORQUE-WRENCH",
        "description": "POWER-AIR/AIR-TOOLS/TORQUE-WRENCH",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075902",
        "name": "POWER-AIR/AIR-TOOLS/WORKSHOP-PRESS",
        "description": "POWER-AIR/AIR-TOOLS/WORKSHOP-PRESS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "650173f35493356e09035946",
        "name": "POWER-AIR/BATTERIES",
        "description": "POWER-AIR/BATTERIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075903",
        "name": "POWER-AIR/CHARGERS",
        "description": "POWER-AIR/CHARGERS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f37a5cc8041473041f86",
        "name": "POWER-AIR/CLEAN-ENERGY",
        "description": "POWER-AIR/CLEAN-ENERGY",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075904",
        "name": "POWER-AIR/CLEAN-ENERGY/ACCESSORIES",
        "description": "POWER-AIR/CLEAN-ENERGY/ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907590c",
        "name": "POWER-AIR/CLEAN-ENERGY/POWER-UNITS",
        "description": "POWER-AIR/CLEAN-ENERGY/POWER-UNITS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907590f",
        "name": "POWER-AIR/GENERATORS",
        "description": "POWER-AIR/GENERATORS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075913",
        "name": "PPE/DISPOSABLE-PROTECTION",
        "description": "PPE/DISPOSABLE-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075915",
        "name": "PPE/EYE-PROTECTION",
        "description": "PPE/EYE-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907591f",
        "name": "PPE/FACE-PROTECTION",
        "description": "PPE/FACE-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075922",
        "name": "PPE/HAND-PROTECTION",
        "description": "PPE/HAND-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907592a",
        "name": "PPE/HEARING-PROTECTION",
        "description": "PPE/HEARING-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907592f",
        "name": "PPE/HYDRATION",
        "description": "PPE/HYDRATION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075935",
        "name": "PPE/PROTECTIVE-HEADWEAR",
        "description": "PPE/PROTECTIVE-HEADWEAR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d5f37a5cc8041473041f89",
        "name": "PPE/PROTECTIVE-WORKWEAR",
        "description": "PPE/PROTECTIVE-WORKWEAR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907593d",
        "name": "PPE/RESPIRATORY-GEAR",
        "description": "PPE/RESPIRATORY-GEAR",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075942",
        "name": "PPE/SUN-PROTECTION",
        "description": "PPE/SUN-PROTECTION",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075946",
        "name": "PROCESSING/LAB-EQUIPMENT",
        "description": "PROCESSING/LAB-EQUIPMENT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6508edfe5493356e09035978",
        "name": "PROCESSING/LEAD-WEIGHT",
        "description": "PROCESSING/LEAD-WEIGHT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6523a8616b1777575c517ab1",
        "name": "PROCESSING/REAGENTS",
        "description": "PROCESSING/REAGENTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6630af7b6476f5dc5b4b2b59",
        "name": "PROCESSING/GRINDING-MEDIA",
        "description": "PROCESSING/GRINDING-MEDIA",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "6512600816c14b6892a83a40",
        "name": "QUOTE",
        "description": "default category description",
        "image": "/images/tablets-category.png",
        "attrs": [],
        "__v": 0,
        "display": true
    },
    {
        "_id": "64d338d8bba12d3559075951",
        "name": "SITE-SAFETY/ACCESSORIES",
        "description": "SITE-SAFETY/ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075959",
        "name": "SITE-SAFETY/DG-CABINETS",
        "description": "SITE-SAFETY/DG-CABINETS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907595a",
        "name": "SITE-SAFETY/DG-CABINETS/CLASS-3",
        "description": "SITE-SAFETY/DG-CABINETS/CLASS-3",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907595b",
        "name": "SITE-SAFETY/DG-CABINETS/CLASS-4",
        "description": "SITE-SAFETY/DG-CABINETS/CLASS-4",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907595c",
        "name": "SITE-SAFETY/DG-CABINETS/CLASS-5.1",
        "description": "SITE-SAFETY/DG-CABINETS/CLASS-5.1",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907595d",
        "name": "SITE-SAFETY/DG-CABINETS/CLASS-5.2",
        "description": "SITE-SAFETY/DG-CABINETS/CLASS-5.2",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907595e",
        "name": "SITE-SAFETY/DG-CABINETS/CLASS-6",
        "description": "SITE-SAFETY/DG-CABINETS/CLASS-6",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907595f",
        "name": "SITE-SAFETY/DG-CABINETS/CLASS-8",
        "description": "SITE-SAFETY/DG-CABINETS/CLASS-8",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075961",
        "name": "SITE-SAFETY/DG-CABINETS/DG-OUTDOOR-CABINE",
        "description": "SITE-SAFETY/DG-CABINETS/DG-OUTDOOR-CABINE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075962",
        "name": "SITE-SAFETY/DG-CABINETS/SHELVES-TRAYS",
        "description": "SITE-SAFETY/DG-CABINETS/SHELVES-TRAYS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075963",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075964",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/COMBINATION-UNITS",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/COMBINATION-UNITS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075965",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/FIXED-PEDESTAL-MOUNTED",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/FIXED-PEDESTAL-MOUNTED",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075966",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/FIXED-WALL-MOUNTED",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/FIXED-WALL-MOUNTED",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075967",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/LABORATORY-UNITS",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/LABORATORY-UNITS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075968",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/PORTABLE-EYE-WASH-UNITS",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/PORTABLE-EYE-WASH-UNITS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075969",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/SHOWER-ACCESSORIES",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/SHOWER-ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907596a",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/SHOWER-SPARE-PARTS",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/SHOWER-SPARE-PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907596b",
        "name": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/SIGNS",
        "description": "SITE-SAFETY/EMERGENCY-SHOWERS-EYEWASH/SIGNS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d355907596c",
        "name": "SITE-SAFETY/FIRST-AID",
        "description": "SITE-SAFETY/FIRST-AID",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b7279bc0cda9c9e584c195",
        "name": "SITE-SAFETY/HEIGHT-SAFETY",
        "description": "SITE-SAFETY/HEIGHT-SAFETY",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be78e",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/ACCESSORIES",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/ACCESSORIES",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be78b",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/CONFINED-SPACE-ENTRY",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/CONFINED-SPACE-ENTRY",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be78d",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/HARDWARE-CONNECTORS",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/HARDWARE-CONNECTORS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be787",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/HARNESSES",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/HARNESSES",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be78c",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/HEIGHT-SAFETY-KITS",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/HEIGHT-SAFETY-KITS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be789",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/LANYARDS",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/LANYARDS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be792",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/LIFTING-RIGGING",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/LIFTING-RIGGING",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be790",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/POLE-STRAPS",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/POLE-STRAPS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be791",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/RESCUE-KIT",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/RESCUE-KIT",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be78a",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/ROPES",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/ROPES",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be788",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/SELF-RETRACTING-LIFELINES",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/SELF-RETRACTING-LIFELINES",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be793",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/SLINGS",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/SLINGS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "65b728fdd0ce8f26c00be78f",
        "name": "SITE-SAFETY/HEIGHT-SAFETY/TOOL-LANYARDS",
        "description": "SITE-SAFETY/HEIGHT-SAFETY/TOOL-LANYARDS",
        "image": "/images/tablets-category.png",
        "__v": 3,
        "display": true,
        "attrs": []
    },
    {
        "_id": "651e76146b1777575c517a84",
        "name": "SITE-SAFETY/LOCKOUT",
        "description": "SITE-SAFETY/LOCKOUT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    },
    {
        "_id": "651fc5746b1777575c517a92",
        "name": "SITE-SAFETY/LOCKOUT/ADJUSTABLE-CABLE-LOCKOUT",
        "description": "SITE-SAFETY/LOCKOUT/ADJUSTABLE-CABLE-LOCKOUT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "651fc5f46b1777575c517a98",
        "name": "SITE-SAFETY/LOCKOUT/BLIND-FLANGE",
        "description": "SITE-SAFETY/LOCKS/BLIND-FLANGE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "651fc5306b1777575c517a8f",
        "name": "SITE-SAFETY/LOCKOUT/GROUP-LOCKOUT",
        "description": "SITE-SAFETY/LOCKS/GROUP-LOCKOUT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "651fc5a66b1777575c517a95",
        "name": "SITE-SAFETY/LOCKOUT/JAW-CLEARANCE",
        "description": "SITE-SAFETY/LOCKS/JAW-CLEARANCE",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "651fc6876b1777575c517aa2",
        "name": "SITE-SAFETY/LOCKOUT/LOCKOUT-COVER",
        "description": "SITE-SAFETY/LOCKS/LOCKOUT-COVER",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "651fc65f6b1777575c517a9f",
        "name": "SITE-SAFETY/LOCKOUT/LOCKOUT-STATIONS",
        "description": "SITE-SAFETY/LOCKS/LOCKOUT-STATIONS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "65547ed47752a1732e1dffb0",
        "name": "SITE-SAFETY/LOCKOUT/LOCKS",
        "description": "SITE-SAFETY/LOCKOUT/LOCKS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "651fc61f6b1777575c517a9b",
        "name": "SITE-SAFETY/LOCKOUT/PARTS",
        "description": "SITE-SAFETY/LOCKS/PARTS",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": false,
        "attrs": []
    },
    {
        "_id": "64d338d8bba12d3559075974",
        "name": "SITE-SAFETY/SPILL-CONTAINMENT",
        "description": "SITE-SAFETY/SPILL-CONTAINMENT",
        "image": "/images/tablets-category.png",
        "__v": 2,
        "display": true,
        "attrs": []
    }
]