// Here are two array, must to update both of them
export const mainCategory = [
  {
    label: "PPE",
    link: "PPE",
  },
  {
    label: "SITE SAFETY",
    link: "SITE-SAFETY",
  },
  {
    label: "POWER/AIR",
    link: "POWER-AIR",
  },
  {
    label: "HAND TOOLS",
    link: "HAND-TOOLS",
  },
  {
    label: "INDUSTRIAL",
    link: "INDUSTRIAL",
  },
  // {
  //   label: "FABRICATION",
  //   link: "FABRICATION",
  // },
  {
    label: "MECHANICAL",
    link: "MECHANICAL",
  },
  {
    label: "ELECTRICAL",
    link: "ELECTRICAL",
  },
  {
    label: "MINING",
    link: "MINING",
  },
  {
    label: "QUOTE",
    link: "QUOTE",
  },
];

export const mainCategoryForVisitor = [
  {
    label: "PPE",
    link: "PPE",
  },
  {
    label: "SITE SAFETY",
    link: "SITE-SAFETY",
  },
  {
    label: "POWER/AIR",
    link: "POWER-AIR",
  },
  {
    label: "HAND TOOLS",
    link: "HAND-TOOLS",
  },
  {
    label: "INDUSTRIAL",
    link: "INDUSTRIAL",
  },
  {
    label: "MECHANICAL",
    link: "MECHANICAL",
  },
  {
    label: "ELECTRICAL",
    link: "ELECTRICAL",
  },
  {
    label: "MINING",
    link: "MINING",
  },
];
