export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EDIT_QUANTITY = "EDIT_QUANTITY";
export const EMPTY_CART = "EMPTY_CART";
export const RE_ORDER = "RE_ORDER";
export const FETCH_CART_ITEMS_LOGIN = "FETCH_CART_ITEMS_LOGIN";
export const UPDATE_UNIFORM_PURCHASE = "UPDATE_UNIFORM_PURCHASE";
// export const ADD_TO_BACKORDER = "ADD_TO_BACKORDER";


