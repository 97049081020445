import React from 'react';
import "./AcknowledgementOfCountryComponent.css";


const AcknowledgementOfCountryComponent = () => {

    return <div className="welcome-main">
        <h4>Acknowledgement of Country</h4>
        <p>
            We respectfully acknowledge the Traditional Custodians of the land on which we live and work, and their enduring connection to land, waters, and community.
            <br /> <br className='br-visibility' />
            We pay our respects to Elders past, present, and emerging, and recognise their continuing cultural, spiritual, and educational practises.
            <br /> <br className='br-visibility' />
            We extend that respect to all Aboriginal and Torres Strait Islander peoples and celebrate their ongoing cultural contributions and connections to the lands we share.
        </p>
    </div>
}

export default AcknowledgementOfCountryComponent;